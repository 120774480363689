import { EXTERNAL_SCRIPT } from '@/config'

export function loadExternalScript (name, defer = false, callback) {
  const externalScript = EXTERNAL_SCRIPT[name]
  const customEvent = new Event(`${name}-SCRIPT-LOADED`)
  const s = document.createElement('script')
  s.defer = defer

  s.setAttribute('src', externalScript)
  document.head.appendChild(s)
  s.onload = function () {
    document.dispatchEvent(customEvent)

    typeof callback === 'function' && callback()
  }
}
