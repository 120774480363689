import { mapActions, mapState } from 'vuex'
import { EventBus } from '@/helpers/EventBus.js'
import { TYPE_OF_PAGE, EXCLUDED_SKELETON_NAME } from '@/config'
export default {
  data () {
    return {
      virtualRouteLeave: false,
      goToProductPage: false,
      count: 0
    }
  },
  beforeRouteLeave (to, from, next) {
    setTimeout(() => {
      if (!this.virtualRouteLeave) {
        if (this.activeSkeleton) {
          this.setActiveSkeleton(false)
        }
        this.initSkeleton(to, from)
      }
    }, 100)
    next()
  },
  watch: {
    activeSkeleton (newValue) {
      if (!newValue) {
        if (window.transitionTimeOut) {
          clearTimeout(window.transitionTimeOut)
          window.transitionTimeOut = null
        }
        return
      }
      window.transitionTimeOut = setTimeout(() => {
        if (this.activeSkeleton) {
          this.setActiveSkeleton(false)
          if (this.pageName.startsWith('index')) {
            this.setCheckHeaderColor(true)
          }
        } else {
          clearTimeout(window.transitionTimeOut)
          window.transitionTimeOut = null
        }
      }, +process.env.SKELETON_TIMEOUT || 10000)
    },
    productThumbnailClick (newValue) {
      // TODO: Ce premier if pourra etre enlevé quand les url V3 seront en place
      if (newValue) {
        this.goToProductPage = true
      }
      // Ce deuxieme if permet de simuler un changement de page lorsque l'utilisateur clique sur un produit
      // associé dans une page produit. Sans cela, nuxt ne concidère pas qu'il y a changement de page donc le skeleton
      // ne se lance pas.
      if (newValue && this.pageName.startsWith('p-all')) {
        this.virtualRouteLeave = true
        this.setRouteTo(TYPE_OF_PAGE['p-all'])
        this.scrollTop()
        this.setSpacer(true)
        this.setActiveSkeleton(true)
      }
      this.setProductThumbnailClick(false)
    },
    breadcrumbClick (newValue) {
      if (newValue && /^(all|marques|artists)/.test(this.pageName)) {
        this.virtualTransitionOnListing()
      }
      this.setBreadcrumbClick(false)
    },
    bubbleClick (newValue) {
      if (newValue && /^(all|marques|artists)/.test(this.pageName)) {
        this.virtualTransitionOnListing()
      }
      this.setBubbleClick(false)
    },
    menuClick (newValue) {
      if (newValue && /^(all|marques|artists)/.test(this.pageName)) {
        this.virtualTransitionOnListing()
      }
      this.setMenuClick(false)
    },
    checkHeaderColor (newValue) {
      if (newValue) {
        if (this.pageName.startsWith('index')) {
          EventBus.$emit('setHeaderWhiteColor', false)
        } else {
          EventBus.$emit('setHeaderWhiteColor', true)
        }
        this.setCheckHeaderColor(false)
      }
    }
  },
  computed: {
    ...mapState({
      pageName: state => state.pagename,
      productThumbnailClick: state => state.transitionPage && state.transitionPage.productThumbnailClick,
      bubbleClick: state => state.transitionPage && state.transitionPage.bubbleClick,
      breadcrumbClick: state => state.transitionPage && state.transitionPage.breadcrumbClick,
      menuClick: state => state.transitionPage && state.transitionPage.menuClick,
      activeSkeleton: state => state.transitionPage && state.transitionPage.activeSkeleton,
      clickOnProductThumbnail: state => state.transitionPage && state.transitionPage.clickOnProductThumbnail,
      checkHeaderColor: state => state.transitionPage && state.transitionPage.checkHeaderColor
    })
  },
  mounted () {
    this.setActiveSkeleton(false)
    this.count = 0
    window.onpopstate = () => {
      // Prevent the triggering of skeletons if the user goes back between two account pages.
      if (window.location?.pathname?.includes('account')) {
        this.setActiveSkeleton(false)
      } else {
        this.setActiveSkeleton(true)
      }
      if (this.activeSkeleton && this.count > 0) {
        window.location = window.location.href
      } else {
        this.count += 1
      }
    }
  },
  methods: {
    virtualTransitionOnListing () {
      this.virtualRouteLeave = true
      this.setRouteTo(TYPE_OF_PAGE.all)
      this.scrollTop()
      this.setActiveSkeleton(true)
      this.setSpacer(true)
    },
    initSkeleton (to, from) {
      if (!this.activeSkeleton) {
        // TODO: Hack en attente des url v3 sur lbo
        // Pour le moment lorsque l'on clique sur un productThumbnail nuxt pense que l'utilisateur se rend
        // Sur un listing car l'url ne contient pas de /p/, donc le skeleton de listing est lancé.
        // Sur Ryses pas de soucis car il y a déjà des url V3
        // A terme, enlever cette ternaire pour générer le nom ainsi que tout le système de clique dans le productThumbnail.
        // Donc enlever le set du clickOnProductThumbnail car il ne servira plus.
        const name = this.goToProductPage ? 'p-all' : to.name.split('___')[0]
        if (EXCLUDED_SKELETON_NAME.includes(name)) {
          return
        }
        if (name in TYPE_OF_PAGE) {
          // Refaire un check pour savoir si le to n'est pas dans le tableau des page a exclure
          this.scrollTop()
          this.setRouteTo(TYPE_OF_PAGE[name])
          this.changeSpaceAndHeaderColor(from.name.split('___')[0], name)
          this.setActiveSkeleton(true)
          this.goToProductPage = false
        }
      }
    },
    scrollTop () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
    changeSpaceAndHeaderColor (from, to) {
      const otherPage = [...EXCLUDED_SKELETON_NAME, 'all', 'p-all', 'search-query', 'marques', 'artists'].some(path => from.startsWith(path))
      const allPageToHome = otherPage && to === 'index'
      if (allPageToHome) {
        EventBus.$emit('setHeaderWhiteColor', false)
        this.setSpacer(false)
      } else {
        EventBus.$emit('setHeaderWhiteColor', true)
        this.setSpacer(true)
      }
    },
    ...mapActions({
      setActiveSkeleton: 'transitionPage/setActiveSkeleton',
      setRouteTo: 'transitionPage/setRouteTo',
      setSpacer: 'transitionPage/setSpacer',
      setProductThumbnailClick: 'transitionPage/setProductThumbnailClick',
      setBubbleClick: 'transitionPage/setBubbleClick',
      setBreadcrumbClick: 'transitionPage/setBreadcrumbClick',
      setMenuClick: 'transitionPage/setMenuClick',
      setCheckHeaderColor: 'transitionPage/setCheckHeaderColor'
    })
  }
}
