import { isStaticPage, sanitizeUrlParams } from '@/helpers/pageHelper'
import { INTER_DOMAIN } from '@/config/international/config'
import { URL_PROTOCOL } from '@/config'

export function generateHrefLink (route, globalPageInfo) {
  const staticPage = isStaticPage(route)
  if (staticPage.static || !globalPageInfo?.seo) {
    return []
  }
  const hrefLinkArray = globalPageInfo.seo.alternateUrls.map((item) => {
    const sanitizeUrl = sanitizeUrlParams(item.url.fullUrl)

    return { hid: `i18n-alt-${item.lang}`, rel: 'alternate', href: sanitizeUrl, hreflang: item.lang }
  })

  const sanitizeRoute = sanitizeUrlParams(route.fullPath)

  return [
    ...hrefLinkArray,
    { hid: 'i18n-xd', rel: 'alternate', href: route.domain + sanitizeRoute, hreflang: 'x-default' }
  ]
}
export function generateStaticHrefLink (currentInterStoreId, availableInterStore, path = '') {
  return availableInterStore.map((item) => {
    if (item.id !== currentInterStoreId) {
      return { hid: `i18n-alt-${item.langCode}`, rel: 'alternate', href: `${URL_PROTOCOL}://${INTER_DOMAIN[item.id]}${path}`, hreflang: item.langCode }
    } else {
      return { hid: 'i18n-xd', rel: 'alternate', href: `${URL_PROTOCOL}://${INTER_DOMAIN[currentInterStoreId]}${path}`, hreflang: 'x-default' }
    }
  })
}
