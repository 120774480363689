import { mapActions, mapState, mapGetters } from 'vuex'
import { loadExternalScript } from '@/helpers/loadExternalScript'
import { generateHrefLink, generateStaticHrefLink } from '@/helpers/hrefLink'

export default {
  methods: {
    ...mapActions({
      setPageName: 'setPageName',
      setReloadAsyncData: 'listing/setReloadAsyncData'
    }),
    loadABTasty () {
      if (window.ABTasty) {
        return
      }

      loadExternalScript(
        `${
          // Either loading the international version or the default one
          this.isInternationalDisplay
            ? 'ABTASTY_RYSES'
            : 'ABTASTY'
        }`,
        // Defer script (true)
        true
      )
    }
  },
  data () {
    return {
      dataSeo: null
    }
  },
  // do not put this in created hook as it broke page reloading in tunnel pages
  mounted () {
    this.loadABTasty()
    this.setPageName(this.$route.name)
  },
  created () {
    this.dataSeo = this.seo
  },
  watch: {
    seo (newValue) {
      if (newValue.h1 && this.$route.path === '/promos' && this.reloadAsyncData) {
        this.dataSeo = newValue
        this.setReloadAsyncData(false)
        this.$nuxt.refresh()
      }
    },
    reloadAsyncData () {
      if (this.reloadAsyncData && this.$route.path !== '/promos') {
        this.$nuxt.$loading.finish()
        this.setReloadAsyncData(false)
      }
    }
  },
  computed: {
    ...mapState({
      seo: state => state.seo,
      currentInterStore: state => state.international.currentInterStore,
      availableInterStore: state => state.international.availableInterStore,
      domain: state => state.international.domain,
      reloadAsyncData: state => state.listing.reloadAsyncData,
      globalPageInfo: state => state.globalPageInfo,
      errorWebsite: state => state.website.error
    }),
    ...mapGetters({
      isInternationalDisplay: 'international/isInternationalDisplay'
    }),
    headData () {
      let hrefLink = []
      hrefLink = this.$route.path === '/' ? generateStaticHrefLink(this.currentInterStore.id, this.availableInterStore) : generateHrefLink({ ...this.$route, domain: this.domain }, this.globalPageInfo)

      const faviconFolder = this.isInternationalDisplay ? 'other' : 'fr'

      const head = {
        title: this.dataSeo?.title,
        htmlAttrs: {
          lang: this.currentInterStore.langCode
        },
        meta: [
          {
            hid: 'description',
            name: 'description',
            content: this.dataSeo?.description
          },
          {
            hid: 'viewport',
            name: 'viewport',
            content: 'width=device-width, initial-scale=1.0,  shrink-to-fit=no, maximum-scale=1.0,user-scalable=0'
          }
        ],
        link: [
          { rel: 'icon', sizes: 'any', href: `/favicon/${faviconFolder}/favicon.ico` },
          { rel: 'icon', type: 'image/svg+xml', href: `/favicon/${faviconFolder}/favicon.svg` },
          { rel: 'apple-touch-icon', sizes: '180x180', href: `/favicon/${faviconFolder}/apple-touch-icon.png` },
          { rel: 'mask-icon', color: '#e9252f', href: `/favicon/${faviconFolder}/safari-pinned-tab.svg` },
          { rel: 'icon', type: 'image/png', sizes: '32x32', href: `/favicon/${faviconFolder}/favicon-32x32.png` },
          { rel: 'icon', type: 'image/png', sizes: '16x16', href: `/favicon/${faviconFolder}/favicon-16x16.png` },
          ...hrefLink
        ],
        script: []
      }
      return head
    }
  },
  head () {
    return this.headData
  }
}
